import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { formatDate, currencyFormatter } from '#helpers/displayUtils';
import BaseAPI from '#helpers/base-api.js';
import { convertTransactionTypesToFriendly } from '#helpers/displayUtils.js';

const ebApiClient = new BaseAPI();

const TransactionDetails = ({ transaction }) => {
    const [repaymentIntents, setRepaymentIntents] = useState(null);

    useEffect(() => {
        // console.log('Transaction:', transaction);
        if (transaction.type === 'boost') {
            fetchRepaymentIntents(transaction.id);
        }
    }, [transaction]);

    const fetchRepaymentIntents = async (transactionId) => {
        const response = await ebApiClient.get(`transactions/repayments/${transactionId}`);
        const repayments = response.data;
        setRepaymentIntents(repayments);
    };

    // Helper function to safely format dates
    const safeFormatDate = (dateValue) => {
        if (dateValue == null) return 'N/A';
        try {
            return formatDate(dateValue);
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid Date';
        }
    };

    return (
        <Card>
            <CardHeader>
                <h3>Transaction Details</h3>
            </CardHeader>
            <CardBody>
                <Card>
                    <CardHeader>
                        <strong>Type:</strong> {convertTransactionTypesToFriendly(transaction.type)}
                    </CardHeader>
                    <CardBody>
                        <div>
                            <strong>Net Amount:</strong> {currencyFormatter({ value: transaction.netAmount })}
                        </div>
                        <div>
                            <strong>Fee:</strong> {currencyFormatter({ value: transaction.fee })}
                        </div>
                        <div>
                            <strong>Total Amount:</strong> {currencyFormatter({ value: transaction.amount })}
                        </div>
                        <div>
                            <strong>Balance:</strong> {currencyFormatter({ value: transaction.balance })}
                        </div>
                        <div>
                            <strong>Status:</strong> {transaction.status}
                        </div>
                        <div>
                            <strong>Created At:</strong> {safeFormatDate(transaction?.createdAt)}
                        </div>
                        <div>
                            <strong>Updated At:</strong> {safeFormatDate(transaction?.updatedAt)}
                        </div>
                    </CardBody>
                </Card>

                {/* Repayment Intents for Boost */}
                {transaction.type === 'boost' && repaymentIntents && (
                    <Row>
                        {repaymentIntents.map((intent, index) => (
                            <Col md="6" key={index}>
                                <Card>
                                    <CardHeader>
                                        <strong>Scheduled Repayment On:</strong> <br />
                                        {safeFormatDate(intent.nextPaymentDate)}
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <strong>Status:</strong> {intent.status}
                                        </div>
                                        <div>
                                            <strong>Amount:</strong> {currencyFormatter({ value: intent.amount })}
                                        </div>
                                        <div>
                                            <strong>Fee:</strong> {currencyFormatter({ value: intent.fee })}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}

                {/* Display for Matched Deposit */}
                {(transaction.type === 'matched_deposit' ||
                    transaction.type === 'deposit' ||
                    transaction.type === 'withdrawal') && (
                    <div>
                        <div>
                            <strong>Bank Account:</strong> {transaction.achRequest?.bankAccount.metaBankName || 'N/A'}
                        </div>
                        <div>
                            <strong>Last Four of Account:</strong>{' '}
                            {transaction.achRequest?.bankAccount?.metaAccountMask || 'N/A'}
                        </div>
                        <div>
                            <strong>Bank Balance at time of Request:</strong>
                            {/* I know this is silly.. but we really need to standardize on cents versus dollars -vl 7/20/2024 */}
                            {currencyFormatter({ value: transaction.achRequest?.bankBalance * 100 })}
                        </div>
                    </div>
                )}

                {/* Display for Authorization */}
                {transaction.type === 'authorization' && (
                    <div>
                        <div>
                            <strong>Created At:</strong> {safeFormatDate(transaction?.authorization?.createdAt)}
                        </div>
                        <div>
                            <strong>Auth ID:</strong> {transaction.authorization?.auth_id || 'N/A'}
                        </div>
                        <div>
                            <strong>Auth Type:</strong> {transaction.authorization?.auth_type || 'N/A'}
                        </div>
                        <div>
                            <strong>Authorized Amount:</strong> {currencyFormatter({ value: transaction.amount })}
                        </div>
                        <div>
                            <strong>Merchant:</strong>{' '}
                            {transaction.authorization?.merchant?.merchant_description || 'N/A'}
                        </div>
                        <div>
                            <strong>MCC:</strong> {transaction.authorization?.mcc || 'N/A'}
                        </div>
                        <div>
                            <strong>Response Code:</strong> {transaction.authorization?.response_code || 'N/A'}
                        </div>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default TransactionDetails;
