/**
 * MEthods to help with displaying data
 */
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

/**
 * Formats a date value to a specific format in the America/New_York timezone.
 *
 * @param {number|string} value - The date value to format. Can be a Unix timestamp (number) or an ISO date string.
 * @returns {string} The formatted date string in the format 'yyyy-MM-dd HH:mm:ss zzz'.
 * @throws {TypeError} If the input is neither a number nor a string.
 * @throws {Error} If the input string cannot be parsed as a valid date.
 */
export const formatDate = (value) => {
    let date;

    try {
        if (typeof value === 'number') {
            // Convert Unix timestamp to Date object (expecting seconds, hence multiplying by 1000)
            date = new Date(value * 1000);
        } else if (typeof value === 'string') {
            // Parse ISO string to Date object
            date = parseISO(value);
        } else if (value instanceof Date) {
            // If it's already a Date object, use it directly
            date = value;
        } else {
            throw new Error('Invalid date value');
        }

        // Check if the resulting date is valid
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date');
        }

        return formatInTimeZone(date, 'America/New_York', 'yyyy-MM-dd HH:mm:ss zzz');
    } catch (error) {
        // console.warn('Error in formatDate:', error.message);
        // Fallback to a default date
        const fallbackDate = new Date('2000-01-01T00:00:00Z');
        return formatInTimeZone(fallbackDate, 'America/New_York', 'yyyy-MM-dd HH:mm:ss zzz');
    }
};

/**
 * Formats a cent amount into a dollar string with a dollar sign, two decimal places, and thousands separators.
 * Returns 'N/A' for non-numeric inputs.
 *
 * @param {Object} params - The parameters object.
 * @param {number|*} params.value - The amount in cents to format. Non-numeric values will result in 'N/A'.
 * @returns {string} The formatted dollar amount as a string, or 'N/A' for non-numeric inputs.
 *
 * @example
 * // Returns "$1,234.56"
 * currencyFormatter({ value: 123456 });
 *
 * @example
 * // Returns "$10.50"
 * currencyFormatter({ value: 1050 });
 *
 * @example
 * // Returns "$1,000,000.00"
 * currencyFormatter({ value: 100000000 });
 *
 * @example
 * // Returns "N/A"
 * currencyFormatter({ value: "invalid" });
 *
 * @example
 * // Returns "N/A"
 * currencyFormatter({ value: null });
 */
export const currencyFormatter = ({ value }) => {
    if (typeof value !== 'number' || isNaN(value)) {
        return 'N/A';
    }
    const dollars = value / 100;
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(dollars);
};

/**
 * Formats a dollar amount into a string with a dollar sign, two decimal places, and thousands separators.
 *
 * @param {Object} params - The parameters object.
 * @param {number} params.value - The dollar amount to format.
 * @returns {string} The formatted dollar amount as a string.
 *
 * @example
 * // Returns "$1,234.56"
 * dollarFormatter({ value: 1234.56 });
 *
 * @example
 * // Returns "$10.50"
 * dollarFormatter({ value: 10.5 });
 */
export const dollarFormatter = ({ value }) => {
    if (typeof value !== 'number' || isNaN(value)) {
        return '';
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
    // return '$' + value.toFixed(2).toLocaleString();
};

export const convertTransactionTypesToFriendly = (type) => {
    const typeMappings = {
        boost: 'Boost',
        matched_deposit: 'Matched Deposit',
        authorization: 'Authorization',
        deposit: 'Deposit',
        withdrawal: 'Withdrawal',
        payment: 'Payment',
        'merchant credit': 'Merchant Credit',
        reversal: 'Reversal',
        card_deposit: 'Card Deposit',
    };

    // Use the type to get the corresponding user-friendly string from the mapping,
    // or default to the original type if it's not found
    return typeMappings[type] || type;
};
