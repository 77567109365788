import React, { useState, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from "socket.io-client";
import * as layoutConstants from '../constants/layout';
import { Container } from 'reactstrap';
import { changeSidebarTheme, changeSidebarType, changeSocketMessage } from '../redux/actions';
import ThemeCustomizer from '../components/ThemeCustomizer';
import { getUserAccessToken } from '../helpers/authUtils';
import EdgeToast from '../components/EdgeToast';

const LeftSidebar = React.lazy(() => import('../components/LeftSidebar'));
const Topbar = React.lazy(() => import('../components/Topbar'));
const Footer = React.lazy(() => import('../components/Footer'));
const RightSidebar = React.lazy(() => import('../components/RightSidebar'));

const emptyLoading = () => <div></div>;
const loading = () => <div className="text-center"></div>;
let socket;
const Vertical = (props) => {
    const dispatch = useDispatch();
    const [children, setChildren] = useState(null);
    const [isCondensed, setIsCondensed] = useState(false);
    const [isLight, setIsLight] = useState(false);
    const layout = useSelector(state => state.Layout);

    const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
    const user = useSelector(state => state.Auth);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const [timeoutId, setTimeoutId] = useState('');
    const toggleToast = () => setShowToast(!showToast);
    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        const tId = setTimeout(() => {
            setShowToast(false);
        }, 30000);
        setTimeoutId(tId);
    };


    useEffect(() => {
        const token = getUserAccessToken() ? getUserAccessToken().token : '';
        // if (socket && socket.connected) {
        //     socket.disconnect();
        // }
        if (!socket && token) {
            socket = io(ENDPOINT);
            socket.emit('login', { token }, (res) => {
                console.log('data emit(project-message)');
                console.log(res);
            });
            socket.on('message', (message) => {
                showMessageToast('success', 'New Authorization', message.text);
            });

            socket.on('sila.link-account', (message) => {
                dispatch(changeSocketMessage('sila.link-account'));
                showMessageToast('success', message.title, message.text);
            });
            socket.on('plaid.failed-verify', (message) => {
                dispatch(changeSocketMessage('plaid.failed-verify'));
                showMessageToast('danger', message.title, message.text);
            });
        }

        return () => {
            if (socket && socket.connected) {
                // socket.disconnect();
            }
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }

    }, [ENDPOINT, user,]);

    
    useEffect(() => {
        window.addEventListener('resize', updateDimensions);

        dispatch(changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK));

        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
        }
        setChildren(props.children);
        setIsCondensed(layout.leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
        setIsLight(layout.leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_LIGHT);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, [dispatch]);

    const openLeftMenu = () => {
        if (document.body) document.body.classList.add('sidebar-enable');
    };

    const updateDimensions = () => {
        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
        } else if (window.innerWidth > 1028) {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED));
        }
    };

    return (
        <div className="app">
            <div className="wrapper">
                <Suspense fallback={emptyLoading()}>
                    <LeftSidebar
                        {...props}
                        isCondensed={isCondensed}
                        isLight={isLight}
                        hideUserProfile={true}
                    />
                </Suspense>

                <div className="content-page">
                    <div className="content">
                        <Suspense fallback={emptyLoading()}>
                            <Topbar {...props} openLeftMenuCallBack={openLeftMenu} hideLogo={true} />
                        </Suspense>

                        <Container fluid>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>

                    <Suspense fallback={emptyLoading()}>
                        <Footer {...props} />
                    </Suspense>
                </div>
                <EdgeToast isOpen={showToast} toggle={toggleToast} type={toastType} title={toastTitle} content={toastContent} />
            </div>

            <Suspense fallback={emptyLoading()}>
                <RightSidebar {...props}>
                    <ThemeCustomizer />
                </RightSidebar>
            </Suspense>
        </div>
    );
};

export default Vertical;
