import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import BaseAPI from '#helpers/base-api.js';
import { FormGroup, Label, Input, Button, Col, FormText, FormFeedback } from 'reactstrap';

const EditAffiliatePage = () => {
    const ebApiClient = new BaseAPI();
    const { affiliate_id } = useParams();
    const history = useHistory();
    // Initializing with Default Values to assist with form validation
    const [affiliateData, setAffiliateData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [boostLevels, setBoostLevels] = useState([]);
    const [errors, setErrors] = useState({});
    const hasErrors = Object.values(errors).some((error) => error);

    async function getBoostLevels() {
        const boostLevels = await ebApiClient.get('boostLevels');
        setBoostLevels(boostLevels.data);
    }
    // Assuming ebApiClient is set up elsewhere and affiliate_id is passed to the function or globally available
    async function fetchAffiliate(affiliate_id) {
        setIsLoading(true);
        try {
            console.log('Fetching affiliate with ID:', affiliate_id);

            const params = {
                filter: JSON.stringify({ _id: affiliate_id }),
            };

            const response = await ebApiClient.get('affiliates', params);

            if (response.data.results.length > 0) {
                setAffiliateData(response.data.results[0]);
            } else {
                console.log('No affiliate data found for ID:', affiliate_id);
            }
        } catch (error) {
            console.error('Failed to fetch affiliate with ID:', affiliate_id, error);
            // Optionally, handle different types of errors differently
            // e.g., if (error.response && error.response.status === 404) { ... }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getBoostLevels();
        fetchAffiliate(affiliate_id);
    }, [affiliate_id]);

    const validateField = (name, value) => {
        let error = '';
        if (name === 'name') {
            if (value.trim() === '') {
                error = 'Name is required.';
            }
        }
        if (name === 'fastFee' || name === 'interChangeFee') {
            if (value === '') {
                error = 'Fast Fee Rev Share is required.';
            } else if (value < 0 || value > 100) {
                error = 'Fast Fee Rev Share must be between 0 and 100.';
            }
        }
        if (name === 'galileoCardConfig') {
            if (value.trim() === '') {
                error = 'Galileo Card Configuration is required.';
            }
        }
        if (name === 'galileoProgram') {
            if (value === '' || value <= 0) {
                error = 'Galileo Program must be greater than 0.';
            }
        }
        if (name === 'applicationHost') {
            if (value.trim() === '') {
                error = 'Application Host is required.';
            }
        }
        return error;
    };

    const handleSelectBoxChange = (field, value) => {
        setAffiliateData((prev) => ({ ...prev, [field]: value }));
    };

    const handleBoostLevelChange = (selectedName) => {
        const selectedBoostLevel = boostLevels.find((level) => level.name === selectedName);
        setAffiliateData((prev) => ({
            ...prev,
            defaultBoostLevel: selectedBoostLevel,
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const error = validateField(name, value);

        setAffiliateData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleUpdate = async () => {
        console.log('Update payload:', affiliateData);
        try {
            const response = await ebApiClient.put(`affiliates/${affiliateData.id}`, affiliateData);
            console.log('Update response:', response.data);
            history.push('/settings/list/affiliates'); // Redirect or handle post-update logic
        } catch (error) {
            console.error('Failed to update affiliate', error);
        }
    };

    const handleCancel = () => {
        history.push('/settings/list/affiliates');
    };

    const handleActiveChange = (event) => {
        const { name, checked } = event.target;
        setAffiliateData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <div>
            <h1>Edit Affiliate</h1>

            <FormGroup row>
                <Label sm={4}>Everflow Aff ID:</Label>
                <Col sm={8}>
                    <Input
                        type="text"
                        name="affId"
                        value={affiliateData.affId || ''}
                        onChange={handleInputChange}
                        readOnly
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>Name:</Label>
                <Col sm={8}>
                    <Input
                        type="text"
                        name="name"
                        value={affiliateData.name || ''}
                        onChange={handleInputChange}
                        invalid={!!errors.name}
                    />
                    {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>Interchange Rev Share (%):</Label>
                <Col sm={8}>
                    <Input
                        type="number"
                        name="interChangeFee"
                        value={affiliateData.interChangeFee || ''}
                        onChange={handleInputChange}
                        invalid={!!errors.interChangeFee}
                    />
                    {errors.interChangeFee && <FormFeedback>{errors.interChangeFee}</FormFeedback>}
                    <FormText color="muted">Rev Share percentage from Interchange Fees</FormText>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>Fast Fee Rev Share (%):</Label>
                <Col sm={8}>
                    <Input
                        type="number"
                        name="fastFee"
                        value={affiliateData.fastFee || ''}
                        onChange={handleInputChange}
                        invalid={!!errors.fastFee}
                    />
                    {errors.fastFee && <FormFeedback>{errors.fastFee}</FormFeedback>}
                    <FormText color="muted">Rev Share percentage from Fast Rev Fees</FormText>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>Galileo Card Configuration </Label>
                <Col sm={8}>
                    <Input
                        type="string"
                        name="galileoCardConfig"
                        value={affiliateData.galileoCardConfig || ''}
                        onChange={handleInputChange}
                        invalid={!!errors.galileoCardConfig}
                    />
                    {errors.galileoCardConfig && <FormFeedback>{errors.galileoCardConfig}</FormFeedback>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>Galileo Program </Label>
                <Col sm={8}>
                    <Input
                        type="number"
                        name="galileoProgram"
                        value={affiliateData.galileoProgram === '' ? '' : affiliateData.galileoProgram}
                        onChange={handleInputChange}
                        invalid={!!errors.galileoProgram}
                    />
                    {errors.galileoProgram && <FormFeedback>{errors.galileoProgram}</FormFeedback>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>{'Default Tier: '}</Label>
                <Col sm={8}>
                    <Input
                        type="select"
                        value={affiliateData.defaultBoostLevel?.name}
                        onChange={(e) => handleBoostLevelChange(e.target.value)}>
                        {boostLevels.map((level) => (
                            <option key={level.name} value={level.name}>
                                {level.label}
                            </option>
                        ))}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>Application Host </Label>
                <Col sm={8}>
                    <Input
                        type="string"
                        name="applicationHost"
                        value={affiliateData.applicationHost || ''}
                        onChange={handleInputChange}
                        invalid={!!errors.applicationHost}
                    />
                    {errors.applicationHost && <FormFeedback>{errors.applicationHost}</FormFeedback>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>{'Active: '}</Label>
                <Col sm={8}>
                    <Input
                        type="checkbox"
                        name="active"
                        checked={affiliateData.active}
                        onChange={(e) => handleActiveChange(e)}
                        style={{ margin: 0 }}
                    />
                </Col>
            </FormGroup>

            <Button onClick={handleUpdate} disabled={hasErrors} className="mr-2">
                Update Affiliate
            </Button>
            <Button color="secondary" onClick={handleCancel}>
                Cancel
            </Button>
        </div>
    );
};

export default EditAffiliatePage;
