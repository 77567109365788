import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, CardHeader, Button } from 'reactstrap';
import Enums from '../../../constants/enums';
import { formatDate } from '#helpers/displayUtils.js';

const UserDetails = ({ userData, onMatchGalileo, onChangeCardStatus }) => {
    const history = useHistory(); // Hook to enable programmatic navigation
    const displayValue = (value) => value || 'N/A';

    const goBack = () => {
        history.push('/users');
    };

    return (
        <React.Fragment>
            <Button color="primary" onClick={goBack} style={{ marginBottom: '20px' }}>
                Back to the List of Users
            </Button>
            <Container className="user-details">
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md={4}>
                                        <strong>Email:</strong>
                                        <br /> {displayValue(userData.email)}
                                    </Col>
                                    <Col md={2}>
                                        <strong>EDGE Balance:</strong>
                                        <br />{' '}
                                        <NumericFormat
                                            value={userData.boostBalance || 0}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <strong>Galileo Balance:</strong>
                                        <br />{' '}
                                        <NumericFormat
                                            value={userData.card?.balance || 0}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <strong>Cashback Earned:</strong>{' '}
                                        <NumericFormat
                                            value={userData.cashbackEarned || 0}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Button color="primary" onClick={onMatchGalileo}>
                                            Match Galileo
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <CardText>
                                            <strong>Name:</strong> {displayValue(userData.name)}
                                        </CardText>
                                        <CardText>
                                            <strong>Status:</strong> {displayValue(userData.status)}
                                        </CardText>
                                        <CardText>
                                            <strong>Street:</strong> {displayValue(userData.address)}
                                        </CardText>
                                        <CardText>
                                            <strong>City:</strong> {displayValue(userData.city)}
                                        </CardText>
                                        <CardText>
                                            <strong>State:</strong> {displayValue(userData.state)}
                                        </CardText>
                                        <CardText>
                                            <strong>Zip:</strong> {displayValue(userData.zip)}
                                        </CardText>
                                    </Col>
                                    <Col md={4}>
                                        <CardText>
                                            <strong>Affiliate ID:</strong> {displayValue(userData.affiliateId)}
                                        </CardText>
                                        <CardText>
                                            <strong>Offer ID:</strong> {displayValue(userData.offerId)}
                                        </CardText>

                                        <CardText>
                                            <strong>KYC Completed?:</strong> {displayValue(userData.alloyStatus)}
                                        </CardText>
                                        <CardText>
                                            <strong>KYC Status:</strong> {displayValue(userData.alloyOutcome)}
                                        </CardText>
                                        <CardText>
                                            <strong>Setup Status:</strong> {displayValue(userData.setupStatus)}
                                        </CardText>
                                    </Col>
                                    <Col md={4}>
                                        <CardText>
                                            <strong>User Tier:</strong> {displayValue(userData.boostLevel)}
                                        </CardText>

                                        <CardText>
                                            <strong>Created On</strong> {formatDate(userData.createdAt)}
                                        </CardText>
                                        <CardText>
                                            <strong>Last Login At:</strong> {formatDate(userData.last_login)}
                                        </CardText>

                                        <CardText>
                                            <strong>Last Update:</strong> {formatDate(userData.updatedAt)}
                                        </CardText>

                                        <CardText>
                                            <strong>Last Login IP:</strong> {displayValue(userData.login_ip)}
                                        </CardText>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={8}>
                        <Card>
                            <CardHeader tag="h5">
                                <Row>
                                    <Col md={8}>Galileo Details ({displayValue(userData.card?.prnNumber)})</Col>
                                    <Col md={4} className="d-flex">
                                        {userData.card?.cardNumber && (
                                            <Button
                                                color="primary"
                                                onClick={onChangeCardStatus}
                                                disabled={userData.card?.isProcessing}>
                                                Change Card Status
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <CardText>
                                    <strong>Card Number:</strong> {displayValue(userData.card?.cardNumber)}
                                </CardText>
                                <CardText>
                                    <strong>Account Status:</strong> {displayValue(userData.card?.accountStatus)}
                                </CardText>
                                <CardText>
                                    <strong>Card Status:</strong> {displayValue(userData.card?.cardStatus)}
                                </CardText>
                                <CardText>
                                    <strong>Balance:</strong>{' '}
                                    <NumericFormat
                                        value={userData.card?.balance || 0}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        decimalScale={2}
                                    />
                                </CardText>
                                <CardText>
                                    <strong>Last Update:</strong> {displayValue(userData.card?.updatedAt)}
                                </CardText>
                                {userData.card?.cardStatus != Enums.GalileoCardStatusText.ACTIVE && (
                                    <CardText>
                                        <strong>Reason for Disabling:</strong>{' '}
                                        {displayValue(userData.card?.reasonForInactive)}
                                    </CardText>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <CardHeader tag="h5">CrossRiver Details</CardHeader>
                            <CardBody>
                                <CardText>
                                    <strong>CIF Number:</strong> {displayValue(userData.crossRiverCustomer?.cifNumber)}
                                </CardText>
                                <CardText>
                                    <strong>Status:</strong> {displayValue(userData.crossRiverCustomer?.status)}
                                </CardText>
                                <CardText>
                                    <strong>OFAC:</strong> {displayValue(userData.crossRiverCustomer?.ofac)}
                                </CardText>
                                <CardText>
                                    <strong>PEP Scan:</strong> {displayValue(userData.crossRiverCustomer?.pepScan)}
                                </CardText>
                                <CardText>
                                    <strong>PEP Scan:</strong> {displayValue(userData.crossRiverCustomer?.pepScan)}
                                </CardText>
                                <CardText>
                                    <strong>Updated On:</strong> {formatDate(userData.crossRiverCustomer?.updatedAt)}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default UserDetails;
